<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondMotherSettings.vue
Description: This files contians UI components of pond mother tab in settings. It is the parent component of pond mother settings table
-->
<template>
  <el-row
    class="pm-table"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-row v-if="!loading && Ponds.length > 0">
      <el-alert
        ref="error_alert"
        v-if="ehm__unhandledErrorMessage !== ''"
        :title="ehm__unhandledErrorMessage"
        type="error"
      ></el-alert>

      <layout-toolbar type="flex" justify="end" style="margin: 10px 10px">
        <er-input
          v-show="!loading"
          inputType="search"
          v-model="search"
          size="mini"
          :placeholder="$t('Comn_search')"
          @change="handelSearchChange"
        ></er-input>
        <er-button
          btnType="save"
          size="mini"
          :showLabel="true"
          :showIcon="true"
          :disabled="isReadOnly"
          :loading="loading"
          v-show="!loading"
          @click="submitUpdatedDetails"
        ></er-button>
        <er-button
          btnType="cancel"
          size="mini"
          :showLabel="true"
          :disabled="isReadOnly"
          @click="initComponent"
          v-show="!loading"
        ></er-button>

        <div @click="handleOpenDialog">
          <span class="material-icons-outlined action-icon"> queue </span>
        </div>
        <div>
          <er-dropdown
            placement="bottom"
            @item-click="handleTableDataDownload"
            trigger="click"
            class="highlight"
            :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
          >
            <template class="el-dropdown-link download_button" slot="suffix">
              <span class="material-icons-outlined action-icon">
                file_download
              </span>
            </template>
            <template v-slot:item-title="{ item }">{{
              $t(item.value)
            }}</template>
          </er-dropdown>
        </div>
      </layout-toolbar>
      <el-row class="pm-settings-table">
        <er-data-tables
          ref="pondmothersettingstable"
          uniqueId="pm-settings__table"
          :tableData="dataValues"
          :columns="tableColumns"
          v-show="!loading"
          :elTableProps="tableProps"
          :merge="{ startColumnNumber: 1, endColumnNumber: 2 }"
          :pagination-props="$constants.table['pagination-props']"
          :sortMethodForColumns="sortMethods"
          :actionColumn="false"
          type="white-header-table"
          :searchOnTableData="search"
          :key="$i18n.locale"
        >
          <template v-slot:pond_title_header="{ header }">
            {{ $t(header.label) }}
          </template>
          <template v-slot:code_header="{ header }">
            {{ $t(header.label) }}
          </template>
          <template v-slot:title_header="{ header }">
            {{ $t(header.label) }}
          </template>
          <template v-slot:kg_dispense_time_header="{ header }">
            {{ $t(header.label) }}
          </template>
          <template v-slot:mode_header="{ header }">
            {{ $t(header.label) }}
          </template>
          <template v-slot:pond_title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="pond_title"
              :withoutHighlightStrings="row.data.pond_title"
          /></template>

          <template v-slot:code="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="code"
              :withoutHighlightStrings="row.data.code"
            />
          </template>

          <template v-slot:title="{ row }">
            <el-input
              v-model="row.data.title"
              :placeholder="$t('enter_alias_name')"
              @change="handlePMChangeInTable($event, row.data)"
              size="mini"
              :disabled="columnLevelPermissions('MANAGE_DEVICES', row, '')"
            >
            </el-input>
          </template>

          <template v-slot:kg_dispense_time="{ row }">
            <el-input-number
              size="mini"
              :controls="false"
              :min="0"
              :precision="getPrecision(row.data)"
              :disabled="columnLevelPermissions('MANAGE_DEVICES', row, 'kgDisTime')"
              :placeholder="$t('sec-kg')"
              @keypress.native="isNumber($event, row.data)"
              @change="handlePMChangeInTable($event, row.data)"
              v-model="row.data.settings.kg_dispense_time_sec"
            ></el-input-number>
          </template>
          <template v-slot:mode="{ row }">
            <er-select
              v-model="row.data.mode"
              :placeholder="$t('select_mode')"
              :disabled="columnLevelPermissions('MANAGE_SCHEDULES', row, 'mode')"
              size="mini"
              @change="handlePMChangeInTable($event, row.data)"
              popper-class="mode-dropdown"
            >
              <el-option
                v-for="value in StBlockModeOptions(row.data) ? pondModesOfOperationsStBlock : pondModesOfOperationsData(row.data) "
                :label="$t(value.lang_key)"
                :key="value.mode"
                :value="value.mode"
              ></el-option>
            </er-select>
          </template>
          <template v-slot:hopper_type="{ row }">
            <er-select
              v-model="row.data.settings.hopper_type"
              :placeholder="$t('select_type')"
              size="mini"
              @change="handlePMHopperChange($event, row.data)"
              popper-class="mode-dropdown"
              :disabled="columnLevelPermissions('MANAGE_SCHEDULES', row, '')"
            >
              <el-option
                v-for="item in hopperTypes"
                :label="$t(item.lang_key)"
                :key="item.value"
                :value="item.value"
              ></el-option>
            </er-select>
          </template>
        </er-data-tables>
      </el-row>
    </el-row>
    <Loader v-else-if="loading"></Loader>
    <er-no-ponds :tabData="tabData" :disabled="isReadOnly" v-else />
    <component
      :is="`pondMotherActionDialog`"
      :showDialog="dialogVisible"
      @close_dialog="closeDialog"
    ></component>
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import errorHandlerMixin from '@/mixins/errorHandlerMixin.js';
import PondMother from '@/model/pondmother';
import { permissionsToStringMap as permissions } from '@/middleware/pageAccessManager';
import RuleOption from '@/model/ruleOption';
import Loader from '@/components/base/Loader';
import pondMotherActionDialog from './pondMotherActionDialog';
import { pondMotherSettings } from '@/constants/settings';
// import { jsPDF as JSPDF } from "jspdf";
import exportFileDownload from '@/mixins/exportFileDownload';
// import autoTable from "jspdf-autotable";
export default {
  mixins: [errorHandlerMixin, exportFileDownload],
  components: {
    Loader,
    pondMotherActionDialog
  },
  data: function() {
    return {
      search: '',
      data: {},
      dialogVisible: false,
      isPondUnAssign: false,
      pondMotherObj: new PondMother(),
      pondsSelected: [],
      ehm__errMessagesObject: new PondMother(),
      selectedPMIds: [],
      editPMObj: {},
      tableEditPMObj: {},
      pondsData: [],
      loading: false,
      tableProps: {
        // border: true,
        size: 'small',
        // stripe: true,
        //        rowKey: this.getRowKey,
        defaultSort: {
          prop: 'pm_sort_key',
          order: 'ascending'
        },
        height: 'calc(100vh - var(--table-gutter))'
      },
      query: {
        get_all: true,
        order_by: null,
        order_type: null,
        un_assigned_only: false,
        location_id: null
      },
      editPermissions: {
        keyToValidateAccess: 'PERMISSIONS',
        PERMISSIONS: [permissions.MANAGE_DEVICES, permissions.MANAGE_SCHEDULES]
      }
    };
  },
  props: {
    tabData: {
      default: {}
    }
  },
  computed: {
    ...mapGetters({
      PondMothers: 'pondmother/getPondMothers',
      mapPondIdPond: 'pond/getMapPondidPond',
      mapStIdSt: 'shrimptalk/getMapStidSt',
      Ponds: 'pond/getPonds',
      getPmIdPm: 'pondmother/getPmIdPm'
    }),
    ...mapGetters('user', {
      isReadOnly: 'isReadOnly',
      getCurrUserLocation: 'getCurrUserLocation',
      getPermissions: 'getPermissions',
      getUserProfile: "getUserProfile"
    }),
    handlePermissions() {
      return this.getPermissions;
    },
    getPrecision() {
      return (data) => {
        if (data?.allow_decimal_for_kg_dispense_time) {
          return 2;
        } else {
          return 0;
        }
      };
    },
    selectedPondPMs: function() {
      return this.pondsSelected
        .map((pond) => this.mapPondIdPond.get(pond).pond_mothers)
        .flat(1);
    },
    selectedPondPMIds: function() {
      return this.selectedPondPMs.map((pm) => pm._id);
    },
    pondModesOfOperationsStBlock() {
      return {
        FARMER: { mode: "SCHEDULE", lang_key: "Comn_schedule" },
        MANUAL: { mode: "BASIC", lang_key: "PM_sch_mode4" }
      };
    },
    pondModesOfOperations() {
      return {
        FARMER: { mode: 'SCHEDULE', lang_key: 'Comn_schedule' },
        MANUAL: { mode: 'BASIC', lang_key: 'PM_sch_mode4' },
        SHRIMP_TALK: { mode: 'AUTOMATIC', lang_key: 'PM_automatic' },
        HYBRID: { mode: 'HYBRID', lang_key: 'hybrid' }
      };
    },
    hopperTypes() {
      return {
        PM75: { value: 'PM75', lang_key: 'pm-bin-type.pm75' },
        PM125: { value: 'PM125', lang_key: 'pm-bin-type.pm125' },
        'PM250-S': { value: 'PM250-S', lang_key: 'pm-bin-type.pm250-s' },
        'PM250-C': { value: 'PM250-C', lang_key: 'pm-bin-type.pm250-c' }
      };
    },

    tableColumns() {
      return {
        pond_title: {
          prop: 'pond_title',
          label: this.$t('Comn_pond_name'),
          sortable: 'custom',
          searchProperty: 'pond_title',
          enableSearch: true
        },
        code: {
          prop: 'code',
          label: this.$t('Comn_Device_ID'),
          sortable: 'custom',
          enableSearch: true,
          searchProperty: 'code'
        },
        kg_dispense_time: {
          prop: 'settings.kg_dispense_time_sec',
          label: this.$t('PM_kg_dispensed_time_sec'),
          enableSearch: true,
          searchProperty: 'settings.kg_dispense_time_sec'
        },
        mode: {
          prop: 'mode',
          label: this.$t('mode'),
          enableSearch: true,
          searchProperty: 'mode_lang'
        },
        hopper_type: {
          prop: 'hopper_type',
          label: this.$t('Comn_feeder_type')
          // enableSearch: true,
          // searchProperty: "mode_lang"
        },
        title: {
          prop: 'pm_sort_key',
          label: this.$t('Comn_alias_name'),
          sortable: 'custom',
          searchProperty: 'title',
          enableSearch: true
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.pond_title.label,
          dataKey: 'pond_title',
          emptyValue: '--',
          name: 'pond_title',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },

        {
          header: this.tableColumns.code.label,
          dataKey: 'code',
          emptyValue: '--',
          name: 'code',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.kg_dispense_time.label,
          dataKey: 'settings.kg_dispense_time_sec',
          emptyValue: '--',
          name: 'kg_dispense_time_sec',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.mode.label,
          dataKey: (value) => {
            return this.$t(
              this.pondModesOfOperations[value.managed_by].lang_key
            );
          },
          emptyValue: '--',
          name: 'mode',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.hopper_type.label,
          dataKey: (value) => {
            return this.$t(
              this.hopperTypes[value.settings.hopper_type].lang_key
            );
          },
          emptyValue: '--',
          name: 'hopper_type',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.title.label,
          dataKey: 'title',
          emptyValue: '--',
          name: 'title',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        }
      ];
    },
    dataValues() {
      return Object.values(this.data);
    },
    isTableEditted() {
      return Object.keys(this.tableEditPMObj).length > 0;
    },
    location() {
      return this.$store.getters['user/getCurrUserLocation'];
    },
    validationRules() {
      const deviceLangKey = 'Comn_pond_mother';
      const fieldLangKey = {
        kg_dispense_time: 'Comn_feed_dispensed_kg',
        title: 'Comn_alias_name'
      };
      const rulesOptionsFunction = (
        fieldLangKey,
        itemTitle,
        minValue,
        maxValue
      ) => {
        return [
          RuleOption.createBasicRule(
            'REQUIRED',
            fieldLangKey,
            deviceLangKey,
            itemTitle
          ),
          RuleOption.createBasicRule(
            'MIN_EQUAL',
            fieldLangKey,
            deviceLangKey,
            itemTitle,
            minValue
          ),
          RuleOption.createBasicRule(
            'MAX_EQUAL',
            fieldLangKey,
            deviceLangKey,
            itemTitle,
            maxValue
          )
        ];
      };
      return (payloadItem) => {
        const itemTitle = payloadItem.title || payloadItem.code;
        return [
          {
            field: 'kg_dispense_time',
            rules: rulesOptionsFunction(
              fieldLangKey.kg_dispense_time,
              itemTitle,
              pondMotherSettings.kg_dispense_time_secs[0],
              pondMotherSettings.kg_dispense_time_secs[1],
              fieldLangKey.kg_dispense_time
            )
          },
          {
            field: 'title',
            rules: [
              RuleOption.createBasicRule(
                'REQUIRED',
                fieldLangKey.title,
                deviceLangKey,
                itemTitle
              )
            ]
          }
        ];
      };
    },
    sortMethods() {
      return {
        pond_title: this.sortPondWise,
        pm_sort_key: this.sortAliasName,
        code: this.sortCode
      };
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions({
      fetchAllPondMothers: 'pondmother/fetchAllPondMothers',
      fetchAllShrimpTalks: 'shrimptalk/fetchAllShrimpTalks',
      fetchAllPonds: 'pond/fetchAllPonds',
      updatePMSettings: 'pondmother/updatePMSettings'
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    pondModesOfOperationsData(data) {
      // console.log("Data---", data.settings?.features_enabled.includes("HYBRID-MODE"));
      // return this.getUserProfile.features.hybrid_mode ? {
      return data.settings?.features_enabled.includes("HYBRID-MODE") ? {
        FARMER: { mode: 'SCHEDULE', lang_key: 'Comn_schedule' },
        MANUAL: { mode: 'BASIC', lang_key: 'PM_sch_mode4' },
        SHRIMP_TALK: { mode: 'AUTOMATIC', lang_key: 'PM_automatic' },
        HYBRID: { mode: 'HYBRID', lang_key: 'hybrid' }
      } : {
        FARMER: { mode: 'SCHEDULE', lang_key: 'Comn_schedule' },
        MANUAL: { mode: 'BASIC', lang_key: 'PM_sch_mode4' },
        SHRIMP_TALK: { mode: 'AUTOMATIC', lang_key: 'PM_automatic' }
      };
    },
    columnLevelPermissions(permission, row, field) {
      if (this.getUserProfile.permissions.length > 0 && this.handlePermissions[permission]) {
        return !this.handlePermissions[permission].UPDATE;
      } else if (field === 'kgDisTime' || field === 'mode' ) {
        return this.isPMExistInSelectedPMs(row.data._id);
      } else {
        return false;
      }
    },
    handelSearchChange() {
      if (this.search !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - PondMother - Search" });
      }
    },
    isNumber(val, data) {
      if (data && data?.allow_decimal_for_kg_dispense_time) {
        if (val.key === ' ') {
          return val.preventDefault();
        }
      } else if (data && !data?.allow_decimal_for_kg_dispense_time) {
        if (val.key === ' ' || val.key === '.') {
          return val.preventDefault();
        }
      }
    },
    sortAliasName(a, b) {
      return this.$commonUtils.localizeComparator(a, b, this.$i18n.value);
    },
    sortPondWise(a, b) {
      return this.$commonUtils.localizeComparator(a, b, this.$i18n.value);
    },
    sortCode(a, b) {
      return this.$commonUtils.alphaNumericComparator(a, b, 1);
    },

    handleTableDataDownload(downloadtype) {
      if (downloadtype === 'Comn_download_as_pdf') {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - PondMother Table - Download" });
    },
    xceldownload() {
      this.exp__Download(
        this.$refs.pondmothersettingstable.$refs.dataTables.sortedData,
        this.downloadColumns,
        'xcel'
      );
    },
    pdfdownload() {
      this.exp__Download(
        this.$refs.pondmothersettingstable.$refs.dataTables.sortedData,
        this.downloadColumns,
        'pdf'
      );
    },

    async initComponent() {
      this.pondsSelected = [];
      this.selectedPMIds = [];
      this.tableEditPMObj = [];
      this.editPMObj = {};
      this.pondMotherObj = new PondMother();
      await this.loadPMList();
    },
    handleOpenDialog() {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.dialogVisible = true;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async closeDialog(event, input) {
      this.dialogVisible = false;
      if (input === 'retrieveData') {
        await this.initComponent();
        this.mixPanelEventGenerator({ eventName: "Settings - PondMother Table - Group Settings - Save" });
      }
    },

    getRowKey(row) {
      return row.pond_mother_id;
    },
    getPond(pond_id) {
      if (this.mapPondIdPond && this.mapPondIdPond.get(pond_id)) {
        return this.mapPondIdPond.get(pond_id);
      } else {
        return { pond_mothers: [] };
      }
    },
    handlePMChangeInTable(event, pm) {
      if (!this.editPMObj[pm._id]) {
        pm.title = pm.title.trim();
        this.$set(this.tableEditPMObj, pm._id, pm);
      }
    },
    handlePMHopperChange(event, pm) {
      if (!this.editPMObj[pm._id]) {
        this.$set(this.tableEditPMObj, pm._id, pm);
      }
    },
    handleCheckAllPMs(value) {
      this.selectedPMIds = value ? this.selectedPondPMIds : [];
      this.initEditPMObj(this.selectedPMIds, this.pondMotherObj);
      // this.isIndeterminatePMs = false;
    },
    initEditPMObj(pmIdArr, pondMotherObj) {
      this.editPMObj = {};
      if (pmIdArr.length > 0) {
        pmIdArr.forEach((pmId) => {
          this.editPMObj[pmId] = this.$lodash.cloneDeep(
            this.getPmIdPm.get(pmId)
          );
        });
      }
    },
    isPMExistInSelectedPMs(pmId) {
      return this.selectedPMIds.indexOf(pmId) > -1;
    },
    handleChangePond(pondIdArr) {
      if (pondIdArr.length > 0) {
        this.pondMotherObj = new PondMother();
        this.handleCheckAllPMs(true);
        Object.keys(this.tableEditPMObj).forEach((pmId) => {
          this.$set(
            this.data,
            pmId,
            this.$lodash.cloneDeep(this.getPmIdPm.get(pmId))
          );
        });
        this.tableEditPMObj = {};
      } else {
        this.handleCheckAllPMs(false);
      }
    },
    handleChangePondMother(pmIdArr) {
      if (pmIdArr.length === 0) {
        this.handleCheckAllPMs(false);
      } else {
        this.pondMotherObj = new PondMother();
        this.initEditPMObj(pmIdArr, this.pondMotherObj);
      }
    },
    async submitUpdatedDetails() {
      const payLoadObj = {};
      // values editted in the select element
      Object.values(this.editPMObj).forEach((pm) => {
        if (pm) {
          payLoadObj[pm._id] = {
            title: this.pondMotherObj.title || pm.title,
            code: pm.code,
            pond_mother_id: pm._id,
            kg_dispense_time:
              this.pondMotherObj.settings.kg_dispense_time_sec ||
              pm.settings.kg_dispense_time_sec,
            mode: this.pondMotherObj.mode || pm.mode,
            shrimp_talk_id: pm.shrimp_talk_id,
            hopper_type: pm.settings.hopper_type
          };
        }
      });
      Object.values(this.tableEditPMObj).forEach((pm) => {
        if (!payLoadObj[pm._id]) {
          payLoadObj[pm._id] = {
            title: pm.title,
            pond_mother_id: pm._id,
            code: pm.code,
            kg_dispense_time: pm.settings.kg_dispense_time_sec,
            mode: pm.mode,
            shrimp_talk_id: pm.shrimp_talk_id,
            hopper_type: pm.settings.hopper_type
          };
        }
      });
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        if (this.selectedPMIds.length > 0) {
          if (
            !this.pondMotherObj.settings.kg_dispense_time_sec &&
            !this.pondMotherObj.mode
          ) {
            throw {
              type: 'FAIL_TO_SAVE',
              errors: [
                {
                  message: `${this.$t('enter_kg_dispensed_time_sec')} ${this.$t(
                    'or_lower_case'
                  )} ${this.$t('select_mode')}`
                }
              ]
            };
          }
        }
        const arrPayload = Object.values(payLoadObj);
        if (this.PondMothers.length === 0) {
          return;
        }
        if (arrPayload.length === 0) {
          throw {
            type: 'FAIL_TO_SAVE',
            errors: [
              {
                message: this.$t('Comn_no_changes_found', {
                  item: this.$t('Comn_pond_mother')
                })
              }
            ]
          };
        }
        const errors = arrPayload.reduce((acc, payloadItem) => {
          acc.push(
            ...this.ehm__ruleValidator(
              payloadItem,
              this.validationRules(payloadItem),
              'REQUIRED'
            )
          );
          return acc;
        }, []);

        if (errors.length > 0) {
          throw {
            type: 'FAIL_TO_SAVE',
            errors
          };
        }
        this.loading = true;
        const payload = Object.values(payLoadObj);
        // {pond_mothers:newSelectedPMIds}
        this.ehm__errorMessagesObject = new PondMother();
        // eslint-disable-next-line no-unused-vars
        await this.updatePMSettings(payload);
        this.mixPanelEventGenerator({ eventName: "Settings - PondMother Table - Save" });
        this.ehm__errorMessagesObject = new PondMother();
        this.$notify({
          title: this.$t('Usrs_success_msg'),
          message: this.$t('PM_upd_success'),
          duration: 5000,
          type: 'success'
        });
        await this.initComponent();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    getPondName(pondId) {
      if (pondId !== '') {
        return (this.mapPondIdPond.get(pondId) || { title: this.$t('empty') })
          .title;
      }
    },
    async loadPMList() {
      this.loading = true;
      try {
        this.query.status = ['ACTIVE'];
        await Promise.all([
          this.fetchAllPonds({
            location_id: this.location._id,
            status: ['ACTIVE', 'INACTIVE'],
            get_all: true
          }),
          this.fetchAllPondMothers({
            location_id: this.location._id,
            get_all: true
          }),
          this.fetchAllShrimpTalks({
            location_id: this.location._id,
            get_all: true
          })
        ]);
        this.pondsData = this.$lodash.cloneDeep(this.Ponds);
        this.PondMothers.forEach((pm) => {
          pm.pond_title = this.getPondName(pm.pond_id);
          pm.pm_sort_key = `${pm.pond_title}|${pm.title || pm.code}`;
          pm.mode = pm.mode
            ? pm.mode
            : this.pondModesOfOperations[pm.managed_by].mode;
          pm.backendDispensedValue = pm.settings.kg_dispense_time_sec;
          this.$set(this.data, pm._id, pm);
        });
        console.log('this.data', this.data);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.pondmothersettingstable) {
            this.$refs.pondmothersettingstable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    StBlockModeOptions(data) {
      if (data.shrimp_talk_id && this.mapStIdSt.get(data.shrimp_talk_id)?.block_shrimp_talk) {
        return true;
      }
      return false;
    },
  }
};
</script>

<style lang="scss">
.pm-dropdown {
  .el-select-dropdown__item {
    font-size: 11px !important;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_small,
    //   $app_font_size_1,
    //   $app_font_size_2
    // );
    display: flex;
    align-items: center;
  }
}
.mode-dropdown {
  .el-select-dropdown__item {
    font-size: 11px;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_small,
    //   $app_font_size_1,
    //   $app_font_size_2
    // );
    display: flex;
    align-items: center;
  }
  .el-select-dropdown {
    min-width: 120px !important;
  }
  min-width: 120px !important;
}
.pm-table {
  @include responsiveProperty(--table-gutter, 218px, 218px, 223px);
  $input_width: 200px;

  .action-icon {
    cursor: pointer;
    color: black;
    opacity: 0.3;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    @include icon-size(icon-font);
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .big-input-container {
    .el-select {
      width: 150px;
    }
  }
  .el-input__inner {
    font-size: 11px !important;
  }

  .settings-post-header-center {
    display: flex;
    justify-content: space-evenly;
  }
  .pm-settings-table {
    padding: 0 12px;
    .el-select {
      .el-input--mini {
        width: 120px;
      }
    }
    .el-input__inner {
      width: 120px;
      font-size: 11px !important;
    }
    .el-input-number--mini {
      width: 120px;
    }
    .el-input-number {
      .el-input--mini {
        .el-input__inner {
          width: 80px;
          font-size: 11px !important;
        }
      }
    }
  }
  .pm-table__header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .post-header-pm-selection {
      .el-input--mini {
        max-width: 160px;
      }
    }
    // background: white;
  }
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > div:nth-child(n + 1) {
      margin-right: 10px;
    }
    .input_all_kg_disp {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      overflow: hidden;
      height: 30px;
      line-height: 32px;
      &:after {
        position: absolute;
        content: '';
        right: 0px;
        width: 20px;
        height: 32px;
        background-color: white;
        z-index: 9;
      }
      &.is-disabled {
        &:after {
          background-color: #f5f7fa;
          cursor: not-allowed;
          color: #c0c4cc;
        }
      }

      .el-input__inner {
        border: none;
        border-radius: unset;
        padding: 0px 0px 0px 15px;
      }
    }
  }
  .table {
    margin: 10px;
  }
  .el-pagination__sizes {
    .el-input--mini .el-input__inner {
      height: 22px;
      line-height: 22px;
    }
  }
  .el-pagination__total {
    margin-left: 20px;
  }
  .el-pagination {
    padding-top: 10px;
    padding-bottom: 10px;
    .el-pagination__editor {
      .el-input__inner {
        width: 46px;
      }
    }
  }
  .pm-tag-list {
    display: flex;
    flex-direction: row;
    margin: 10px;
    box-sizing: border-box;
  }
  .el-select-dropdown__item {
    font-size: 11px !important;
  }
}
</style>
